import React, { useRef, useState } from 'react'
import {
  Box,
  Flex,
  Img,
  IconButton,
  useDisclosure,
  Link,
  Icon,
  Divider,
  css,
  Text,
  Button,
  useBoolean,
} from '@chakra-ui/react'
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import { Link as ReactRouterLink } from 'react-router-dom'
import Menu from './menu'
import logo from '../styles/assets/logo.png'
import amazon from 'styles/assets/amazon.png'

const ShopIcon = (props) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g data-name="Layer 2" id="Layer_2">
      <path
        fill="currentColor"
        d="M23.52,29h-15a5.48,5.48,0,0,1-5.31-6.83L6.25,9.76a1,1,0,0,1,1-.76H24a1,1,0,0,1,1,.7l3.78,12.16a5.49,5.49,0,0,1-.83,4.91A5.41,5.41,0,0,1,23.52,29ZM8,11,5.11,22.65A3.5,3.5,0,0,0,8.48,27h15a3.44,3.44,0,0,0,2.79-1.42,3.5,3.5,0,0,0,.53-3.13L23.28,11Z"
      />
      <path
        fill="currentColor"
        d="M20,17a1,1,0,0,1-1-1V8a3,3,0,0,0-6,0v8a1,1,0,0,1-2,0V8A5,5,0,0,1,21,8v8A1,1,0,0,1,20,17Z"
      />
    </g>
    <g id="frame">
      <rect height="32" width="32" fill="none" />
    </g>
  </Icon>
)

const ReadIcon = (props) => (
  <Icon viewBox="0 0 256 256" {...props}>
    <rect fill="none" height="256" width="256" />
    <path
      fill="none"
      d="M128,88a32,32,0,0,1,32-32h64a8,8,0,0,1,8,8V192a8,8,0,0,1-8,8H160a32,32,0,0,0-32,32"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      fill="none"
      d="M24,192a8,8,0,0,0,8,8H96a32,32,0,0,1,32,32V88A32,32,0,0,0,96,56H32a8,8,0,0,0-8,8Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
  </Icon>
)

const Header = (props) => {
  const btnRef = useRef()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Flex
      as={'nav'}
      flex={1}
      direction={'column'}
      borderBottomWidth={1}
      borderBottomColor={'gray.300'}
    >
      <Box
        h={{ sm: '20px', md: '45px', xl: '45px', '2xl': '45px' }}
        css={css({
          '@media print': {
            h: '25px',
          },
        })}
      />
      <Flex h={'30px'} direction={'row'}>
        <Flex flex={1} justifyContent={'flex-start'} alignItems={'center'}>
          <IconButton
            aria-label="Menu"
            title={'Menu'}
            ref={btnRef}
            icon={<HamburgerIcon width={'24px'} height={'24px'} />}
            onClick={onOpen}
            bg={'transparent'}
            borderRadius={'none'}
            tabIndex={0}
            _hover={{
              fontSize: 20,
              transform: 'scale(1.1)',
              transition: 'scale 0.3',
              background: 'transparent',
            }}
            justifyContent={'flex-start'}
          />
          <Divider
            orientation="vertical"
            h={'24px'}
            ml={{ sm: 0, md: '5px' }}
            mr={{ sm: 0, md: '10px' }}
            borderColor={'gray.600'}
          />
          <IconButton
            aria-label="Shop"
            title={'Shop'}
            as={ReactRouterLink}
            to={'/products'}
            icon={<ShopIcon width={'24px'} height={'24px'} />}
            bg={'transparent'}
            borderRadius={'none'}
            tabIndex={0}
            _hover={{
              fontSize: 20,
              transform: 'scale(1.1)',
              transition: 'scale 0.3',
              background: 'transparent',
            }}
          />
          <IconButton
            aria-label="Articles"
            title={'Articles'}
            as={ReactRouterLink}
            to={'/articles'}
            icon={<ReadIcon width={'24px'} height={'24px'} />}
            bg={'transparent'}
            borderRadius={'none'}
            tabIndex={0}
            ml={{ sm: 0, md: '10px' }}
            _hover={{
              fontSize: 20,
              transform: 'scale(1.1)',
              transition: 'scale 0.3',
              background: 'transparent',
            }}
          />
        </Flex>
        <Flex justifyContent={'center'}>
          <Link tabIndex={0} to="/" as={ReactRouterLink} aria-label="Home" title="ecocrafts">
            <Img
              alt="ecocrafts"
              src={logo}
              h={{ sm: '40px', md: '58px' }}
              mt={{ sm: '-10px', md: '-20px' }}
              css={css({ '@media print': { height: '30px' } })}
            />
          </Link>
        </Flex>
        <Flex flex={1} justifyContent={'flex-end'} alignItems={'center'}>
          <Link
            tabIndex={0}
            title={'Amazon Store'}
            href="https://www.amazon.in/storefront?me=A2RFLCXVCXWOSX"
            target="_blank"
            aria-label="amazon"
            _hover={{
              fontSize: 20,
              transform: 'scale(1.1)',
              transition: 'scale 0.3',
              background: 'transparent',
            }}
          >
            <Img src={amazon} w={'30px'} h={'30px'} alt="amazon" />
          </Link>
        </Flex>
      </Flex>
      <Box
        h={{ sm: '20px', md: '45px', xl: '45px', '2xl': '45px' }}
        css={css({
          '@media print': {
            h: '25px',
          },
        })}
      />
      <Menu btnRef={btnRef} onClose={onClose} isOpen={isOpen} />
    </Flex>
  )
}

export const HeaderBanner = () => {
  const key = 'eco-header-banner-expiry'
  const expiry = localStorage.getItem(key)
  const [visibility, setVisibility] = useBoolean(false)
  const removeBanner = () => {
    localStorage.setItem(key, (new Date().getTime() + 12 * 60 * 60 * 1000).toString())
    setVisibility.off()
  }

  useState(() => {
    if (expiry) {
      if (parseInt(expiry, 10) - new Date().getTime() > 0) {
        setVisibility.off()
      } else {
        localStorage.removeItem(key)
        setVisibility.on()
      }
    } else {
      setVisibility.on()
    }
  }, [expiry])

  if (!visibility) {
    return null
  }
  return (
    <Flex h={{ sm: '70px', lg: '80px' }} w={'100%'} color={'white'} alignItems={'center'}>
      <Flex
        flexGrow={1}
        alignItems={'center'}
        justifyContent={{ sm: 'center', lg: 'space-between' }}
        direction={'row'}
      >
        <Text align={{ sm: 'left', md: 'center' }} as={'em'} mb={{ sm: '10px', lg: 0 }}>
          Welcome to ecocrafts, Enjoy complimentary shipping & discounts all week.
        </Text>
        <Flex mr={'20px'}>
          <Button
            to={'/products'}
            as={ReactRouterLink}
            title="Visit Shop"
            borderRadius={0}
            borderWidth={'2px'}
            variant={'outline'}
            color={'white'}
            size={'sm'}
            _hover={{ color: 'green.700', bg: 'white' }}
          >
            Visit Shop
          </Button>
        </Flex>
      </Flex>
      <IconButton
        _hover={{ bg: 'transparent' }}
        color={'white'}
        variant={'ghost'}
        aria-label="Close"
        title="Close"
        icon={<CloseIcon />}
        onClick={removeBanner}
      />
    </Flex>
  )
}
export default Header
