import {
  collection,
  collectionGroup,
  documentId,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from 'firebase/firestore'
import { db } from '../firebase'

const ProductService = {
  all: async (last = null) => {
    let products = {
      data: [],
      last: null,
    }
    const offset = 7

    try {
      let queryRef = query(collectionGroup(db, 'products'), orderBy('sku', 'desc'), limit(offset))

      if (last) {
        queryRef = query(
          collectionGroup(db, 'products'),
          orderBy('sku', 'desc'),
          startAfter(last),
          limit(offset),
        )
      }
      const docSnap = await getDocs(queryRef)

      docSnap.forEach((product) => {
        products.data.push({
          id: product.id,
          data: product.data(),
        })
      })
      if (docSnap.docs.length === offset) {
        products.last = docSnap.docs[docSnap.docs.length - 1]
      }
    } catch (error) {
      console.log(error)
    }
    return products
  },
  categories: async () => {
    let categoryList = []

    try {
      const queryRef = query(collection(db, 'category'))
      const docSnap = await getDocs(queryRef)

      docSnap.forEach((category) => {
        categoryList.push({
          id: category.id,
          data: category.data(),
        })
      })
    } catch (error) {
      console.log(error)
    }
    return categoryList
  },
  category: async (category) => {
    let categoryDetails = {
      details: null,
      products: [],
    }

    try {
      const queryRef = query(collection(db, 'category'), where(documentId(), '==', category))
      const docSnap = await getDocs(queryRef)

      if (docSnap.size == 1) {
        const categoryDoc = docSnap.docs[0]

        categoryDetails.details = categoryDoc.data()
        const pQueryRef = query(
          collection(db, `category/${categoryDoc.id}/products`),
          orderBy('sku', 'desc'),
        )
        const products = await getDocs(pQueryRef)

        products.forEach((product) => {
          categoryDetails.products.push({
            id: product.id,
            data: product.data(),
          })
        })
      }
    } catch (error) {
      console.log(error)
    }
    return categoryDetails
  },
  get: async (productid) => {
    try {
      const queryRef = query(
        collectionGroup(db, 'products'),
        where('slug', '==', productid),
        limit(1),
      )
      const docSnap = await getDocs(queryRef)

      if (docSnap.size == 1) {
        const productDoc = docSnap.docs[0]

        return {
          details: productDoc.data(),
          category: productDoc.ref.parent.parent.id,
        }
      }
    } catch (error) {
      console.log(error)
    }
    return null
  },
}

export default ProductService
