import React, { useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Flex, Img, Stack, Text, Tag, Heading, useBoolean } from '@chakra-ui/react'
import defaultcard from 'styles/assets/pdefaultcard.png'
import { StarIcon } from '@chakra-ui/icons'

const ProductCard = ({ product, bordered = false, smMode = null }) => {
  const [loading, setLoading] = useBoolean(true)
  const { tags, title, images, price, variant, rating = 0 } = product.data
  const half = smMode === 'split'

  const handleLoad = () => {
    setTimeout(() => {
      setLoading.off()
    }, 1000)
  }

  return (
    <Stack
      as={ReactRouterLink}
      title={product.data.caption}
      to={`/product/${product.id}`}
      w={'100%'}
      alignItems={'flex-start'}
      typeof="schema:Product"
      direction={{ sm: half ? 'row' : 'column', md: 'column', lg: 'column' }}
    >
      <Flex
        w={{ sm: half ? '50%' : '100%', md: '100%' }}
        alignItems={'center'}
        mb={'30px'}
        justifyContent={'center'}
        overflow={'hidden'}
        position={'relative'}
        borderWidth={bordered ? 1 : 0}
        borderColor={'gray.300'}
      >
        <Img
          alt={title}
          src={images[0]}
          maxHeight={'100%'}
          zIndex={1}
          verticalAlign={'middle'}
          display={!loading ? 'inline-block' : 'none'}
          _hover={{ transform: 'scale(3)', transition: 'transform 0.6s' }}
          onLoad={handleLoad}
          rel="schema:image"
          resource={images[0]}
        />
        {loading && (
          <Img src={defaultcard} maxHeight={'100%'} zIndex={0} display={'inline-block'} />
        )}
        {(tags.bestseller || tags.new || tags.offer) && (
          <Tag
            position={'absolute'}
            top={'10px'}
            textAlign={'end'}
            right={'10px'}
            bg={'white'}
            borderRadius={0}
            borderLeftWidth={3}
            borderLeftColor={'gray.500'}
            zIndex={3}
            size={'sm'}
          >
            {tags.bestseller ? 'BESTSELLER' : tags.offer ? 'SALE' : tags.new ? 'NEW' : ''}
          </Tag>
        )}
        {rating > 0 && (
          <Flex
            property="schema:rating"
            content={rating}
            position={'absolute'}
            bottom={'10px'}
            textAlign={'end'}
            right={'10px'}
            zIndex={3}
            alignItems={'center'}
            width={'48px'}
            height={'48px'}
            bg={'white'}
            justifyContent={'center'}
            borderWidth={'1px'}
            borderColor={'gray.200'}
            title={`Rating: ${rating} ★`}
          >
            <Text mr={'5px'} fontWeight={'bold'}>
              {rating}
            </Text>
            <StarIcon color={'green.700'} mr={'2px'} display={{ sm: 'none', md: 'inline' }} />
          </Flex>
        )}
      </Flex>
      <Flex direction={'column'} my={'auto'} ml={{ sm: half ? '30px' : 0, md: 0, lg: 0 }}>
        <Heading
          as={'h4'}
          mb={'10px'}
          fontSize={{ sm: '20px', md: '30px' }}
          fontWeight={200}
          property="schema:name"
          content={title}
        >
          {title}
        </Heading>
        {variant && (
          <Text mb={0} color={'gray.700'} fontSize={14} property="schema:variant" content={variant}>
            {variant}
          </Text>
        )}
        <Text rel="schema:offers">
          {price?.onwards ? (
            <Text as="span" property="schema:lowPrice" content={price.value}>
              INR {price.value} onwards
            </Text>
          ) : (
            <>
              <Text as={'s'} property="schema:highPrice" content={price.actual}>
                INR {price.actual}
              </Text>{' '}
              <Text as={'span'} property="schema:lowPrice" content={price.value}>
                INR {price.value}
              </Text>
            </>
          )}
          <Flex as={'span'} property="schema:priceCurrency" content="INR" />
          <Flex as={'span'} property="schema:price" content={price.value} />
          <Flex as={'span'} property="schema:availability" content={'InStock'} />
        </Text>
      </Flex>
    </Stack>
  )
}

export default ProductCard
